.App-content {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  /* flex-direction: column; */
  color: white;
}

.App-link {
  color: #61dafb;
}

